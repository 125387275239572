import './scss/components/_iko-search-form.scss';
import './scss/components/_iko-button.scss';

window.addEventListener('load', e => {
  const SEARCH_BTN = document.querySelector('.navbar-item#search');
  const SEARCH_FORM = document.querySelector('#search-form>.container>.iko-search-form--nav__wrapper');
  const NAVBAR_MENU = document.querySelector('#navigation-menu');
  const TOP_BAR = document.querySelector('.iko-navigation-top-bar');

  if (SEARCH_BTN && !window.location.pathname.includes('search') && !window.location.pathname.includes('suche')) {
    SEARCH_BTN.addEventListener('click', (e) => {
      if (SEARCH_FORM.classList.contains('expanded')) {
        SEARCH_FORM.classList.remove('expanded');
        SEARCH_BTN.classList.remove('is-active')
      } else {
        SEARCH_FORM.classList.add('expanded');
        SEARCH_BTN.classList.add('is-active');
        hideOnClickOutside(SEARCH_FORM);

        if (NAVBAR_MENU && NAVBAR_MENU.classList.contains('is-active')) {
          let MENU_HEIGHT = 0
          if (TOP_BAR) {
            MENU_HEIGHT = NAVBAR_MENU.offsetHeight + 40 + 30;
          } else {
            MENU_HEIGHT = NAVBAR_MENU.offsetHeight + 40;
          }
          SEARCH_FORM.parentElement.parentElement.setAttribute('style', 'top: ' + MENU_HEIGHT + 'px;');
        }
      }

      e.preventDefault();
    });
  }

  setButtonActiveIfSearchPage(SEARCH_BTN);

  function hideOnClickOutside(element) {
    const outsideClickListener = event => {
      if (!element.contains(event.target) && !SEARCH_BTN.contains(event.target) && isVisible(element)) {
        element.classList.remove('expanded');
        SEARCH_BTN.classList.remove('is-active');
        removeClickListener();
      }
    }

    const removeClickListener = () => {
      document.removeEventListener('click', outsideClickListener);
    }

    document.addEventListener('click', outsideClickListener);
  }

  function setButtonActiveIfSearchPage(button) {
    if (window.location.pathname.includes('search') || window.location.pathname.includes('suche')) {
      button.classList.add('is-active');
    }
  }

  const isVisible = elem => !!elem && !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length)
});